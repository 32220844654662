<template>
  <div class="m-login" :style="'background-image: url(' + bg + ');'">
    <div class="form">
      <div class="txt">
        <span>Honggang You</span> is a <br />UIUX & Brand Designer
      </div>
      <form action="">
        <div class="n-form">
          <div class="tit">输入密码查看</div>
          <img src="@/assets/images/new/n-p1.png" alt="" class="bg" />
          <div class="gp">
            <el-form
              :model="ruleForm"
              :rules="rules"
              ref="ruleForm"
              label-width="0px"
              class="demo-ruleForm"
            >
              <el-form-item label="" prop="password">
                <el-input
                  type="password"
                  v-model="ruleForm.password"
                  placeholder="请输入密码"
                ></el-input>
              </el-form-item>
            </el-form>
          </div>
          <div class="bot">
            <div class="btn btn1" @click="checkPwdBtn">确定</div>
          </div>
        </div>
      </form>
      <div class="bottxt">
        <div class="p">WeChat：{{ wechat }}</div>
        <div class="p">Email：{{ email }}</div>
        <div class="p">QQ：{{ qq }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else if (value !== this.pwd) {
        callback(new Error('密码错误'));
      } else {
        callback();
      }
    }
    return {
      bg: '',
      qq: '',
      email: '',
      wechat: '',
      ruleForm: {
        password: '',
      },
      rules: {
        password: [
          // { validator: validatePass, trigger: 'blur' },
          { required: true, message: '请输入密码', trigger: 'blur' },
        ],
      },
    }
  },
  methods: {
    getIndex () {
      let that = this;

      let data = {

      };
      that.$http
        .getIndex(data)
        .then((res) => {
          if (res.ret == 0) {
            // console.log(res);
            let result = res.data
            that.flag = result.index_setting.flag //不加密值为1 加密值为2
            that.pwd = result.index_setting.pwd
            that.bg = result.index_setting.bg
            that.qq = result.qq
            that.email = result.email
            that.wechat = result.wechat

            if (that.flag==1) {
              that.$router.push('/home')
            }
          }
        })
    },
    checkPwdBtn () {
      let that = this;

      let data = {
        id: 0,
        pwd: that.ruleForm.password
      };
      that.$http
        .checkPwd(data)
        .then((res) => {
          if (res.ret == 0) {

            this.$message({
              message: '验证成功！',
              type: 'success',
              duration:1000
            });
            window.localStorage.setItem('yhgislogin', 1)
            window.localStorage.setItem('yhgloginpwd', that.ruleForm.password)
            setTimeout(() => {
              this.$router.push('home')
            }, 1200);

          } else {
            window.localStorage.setItem('yhgislogin', 0)
            this.$message({
              message: res.msg,
              type: 'warning',
              duration:1000
            });
          }
        })

      /* this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          window.localStorage.setItem('yhgislogin', 1)
          this.$router.push('home')
        } else {
          window.localStorage.setItem('yhgislogin', 0)
          console.log('error submit!!');
          return false;
        }
      }); */

    },

  },
  created () {
    this.getIndex()
  }
}
</script>

<style scoped>
</style>